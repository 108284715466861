/* about css */
.about {
  margin: 0rem 5rem;
  color: #000000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-image: url("/images/about-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  z-index: 2;
}

.about--heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about--primary {
  font-size: 5rem;
}

.about--sub {
  font-size: 2rem;
  margin: 3rem 0;
  letter-spacing: 0em;
  text-align: left;
}

.about--skills {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.about--image img {
  height: auto;
  max-width: 100%;
}

.about--skills__catagory {
  /* position: relative;
  top: 10rem;
  left: -20rem; */
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin: 0 4rem;
  color: #fafafa;
}

.skill {
  position: relative;
  height: 3rem;
  width: 100%;
  background-color: #f1e5e5;
  border: 1px solid #0000003d;
  border: 5px;
}

.skill--box {
  top: 0;
  background-color: #382f2ffb;
  height: 100%;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.skill--title {
  font-size: 1rem;
}

.html {
  background: rgb(74, 160, 217);
  background: linear-gradient(
    90deg,
    rgba(74, 160, 217, 1) 0%,
    rgba(93, 130, 193, 1) 32%,
    rgba(140, 100, 167, 1) 100%
  );
  height: 100%;
  width: 80%;
  animation: forward 2000ms 300ms forwards;
}

.js {
  background: rgb(74, 160, 217);
  background: linear-gradient(
    90deg,
    rgba(74, 160, 217, 1) 0%,
    rgba(93, 130, 193, 1) 32%,
    rgba(140, 100, 167, 1) 100%
  );
  height: 100%;
  width: 70%;
  animation: forward 2000ms 300ms forwards;
}

.reactjs {
  background: rgb(74, 160, 217);
  background: linear-gradient(
    90deg,
    rgba(74, 160, 217, 1) 0%,
    rgba(93, 130, 193, 1) 32%,
    rgba(140, 100, 167, 1) 100%
  );
  height: 100%;
  width: 90%;
  animation: forward 2000ms 300ms forwards;
}

.nextjs {
  background: rgb(74, 160, 217);
  background: linear-gradient(
    90deg,
    rgba(74, 160, 217, 1) 0%,
    rgba(93, 130, 193, 1) 32%,
    rgba(140, 100, 167, 1) 100%
  );
  height: 100%;
  width: 80%;
  animation: forward 2000ms 300ms forwards;
}

.reduxjs {
  background: rgb(74, 160, 217);
  background: linear-gradient(
    90deg,
    rgba(74, 160, 217, 1) 0%,
    rgba(93, 130, 193, 1) 32%,
    rgba(140, 100, 167, 1) 100%
  );
  height: 100%;
  width: 90%;
  animation: forward 2000ms 300ms forwards;
}

.flutter {
  background: rgb(74, 160, 217);
  background: linear-gradient(
    90deg,
    rgba(74, 160, 217, 1) 0%,
    rgba(93, 130, 193, 1) 32%,
    rgba(140, 100, 167, 1) 100%
  );
  height: 100%;
  width: 50%;
  animation: forward 2000ms 300ms forwards;
}

@keyframes forward {
  0% {
    width: 0;
  }
}

@media only screen and (max-width: 649px) {
  .about--skills {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }

  .about--skills > div:nth-of-type(1) {
    order: 2;
  }
  .about--skills > div:nth-of-type(2) {
    order: 1;
  }
}
