*,
*::before,
*::after,
:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
}
html,
body {
  font-size: 62.5%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  color: #f4f6fa;
  background: #ffffff;
  font-size: 62.5%;
  font-family: "Raleway";
  /* position: relative; */
}

.body-rapper {
  /* display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 3vw 97vw; */
  width: 100vw;
  height: 100vh;
  position: relative;
}

/* .body-rapper > div:nth-of-type(1) {
  order: 2;
}
.body-rapper > div:nth-of-type(2) {
  order: 1;
} */

.container {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 649px) {
  /* .body-rapper {
    grid-template-rows: 95vh 5vh;
    grid-template-columns: 1fr;
  }

  .body-rapper > div:nth-of-type(1) {
    order: 1;
  }
  .body-rapper > div:nth-of-type(2) {
    order: 2;
  } */
}

.neon {
  /* position: relative;
  display: inline-block;
  color: #03e9f4;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s; */
  letter-spacing: 4px;
  /* overflow: hidden; */
  /* filter: hue-rotate(270deg); */
}
/* .neon:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 200px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
} */
/* a:nth-child(1){
  filter: hue-rotate(270deg);
}
a:nth-child(2){
  filter: hue-rotate(110deg);
} */
.neon span {
  position: absolute;
  display: block;
}
.neon span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ffffff);
  animation: animate1 1s linear infinite;
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
.neon span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #ffffff);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
.neon span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #ffffff);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.neon span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #ffffff);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
