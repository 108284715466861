.navbar {
  z-index: 10;
  height: 100%;
  width: 40px;
  position: fixed;
  left: 0;
  bottom: 0rem;
  display: grid;
  grid-template-rows: 0.5fr 0.5fr 0.5fr;
  grid-template-columns: 40px;
  gap: 10rem;
  background-color: #3bafda;
}

.navbar > div:nth-child(1) {
  background-color: #3bafda;
}

.navbar svg {
  color: #202736;
}

.navbar svg:hover {
  color: #ffffff;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.nav-collection {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.nav-logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.nav-logo > span {
  margin-top: 1rem;
}

@media only screen and (max-width: 649px) {
  .navbar {
    left: 0;
    bottom: 0;
    height: 40px;
    width: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 0.3fr 1fr;
    gap: 0rem;
  }

  .nav-collection {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .nav-logo {
    align-items: center;
  }

  .nav-logo > span {
    margin-top: unset;
  }

  .nav-logo svg {
    color: #ffffff !important;
  }

  .navbar > div:nth-child(1) {
    background-color: black;
  }
}
