/* footer */

/* .footer {
  background-color: #000;
  height: 20vh;
  width: 100%;
  padding-top: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer p {
  color: white;
  font-weight: bold;
  font-size: 16px;
} */

.social-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.social-links a {
  color: rgb(0, 0, 0);
  padding: 0px 5px 0px 5px;
}

.social-links a:hover {
  color: #ffffff;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media only screen and (max-width: 649px) {
  .social-links {
    display: none;
  }
}
