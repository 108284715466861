/* projects css */

.projects {
  height: 100vh;
  widows: 100%;
  display: grid;
  gap: 2rem;
  grid-template-rows: 0.1fr 1fr;
  background-color: #000000;
}

.project--heading {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
}

/* .sideshow-container {
  height: 100%;
  position: relative;
} */

/* side shows */

.slideShow {
  white-space: nowrap;
  width: 100%;
  /* height: 100%; */
  min-height: 15rem;
  overflow: hidden;
  position: relative;
}

.slide {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}

.slideImg {
  width: 90%;
  max-height: 100vh;
  border-radius: 2rem;
}

.slideShow:hover .slideArrow {
  opacity: 1;
}

.slideShow:hover .slidePositionWrapper {
  opacity: 1;
}

.slideArrow {
  opacity: 0;
  position: absolute;
  top: 50%;
  font-size: 4rem;
  line-height: 0;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.5s;
  z-index: 20;
}

.slideArrow:hover {
  color: rgb(223, 5, 5);
}

.arrowRight {
  right: 5%;
}

.arrowLeft {
  left: 5%;
}

.slidePositionWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 90%;
  left: 0;
  z-index: 3;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}

.positionIndicator {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.5rem;
  background: #ffffff50;
  cursor: pointer;
  transition: background 0.5s;
}

.positionIndicator:hover {
  background: white;
}

.slideLabel {
  display: inline-block;
}

.autoSlide {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  z-index: 20;
}

@keyframes slideOutLeft {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translate(100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(100%, 0);
  }
}

@keyframes slideInRight {
  from {
    transform: translate(-100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: 649px) {
  .projects {
    height: 100vh;
  }

  .slideImg {
    width: 90%;
    max-height: 90vh;
    border-radius: 2rem;
  }
}
