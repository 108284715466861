/* contact me */

.contact {
  /* overflow-x: hidden; */
  width: 100vw;
  /* height: 100vh; */
  background-image: url("/images/contact-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.contact--heading {
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin: 5rem 0 0 0; */
  font-size: 4rem;
}

.contact--container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* grid-template-columns: 1fr 1fr; */
  padding: 2rem;
}

.contact--form {
  /* margin-top: 8%; */
  height: 100vh;
  width: 30vw;
  padding: 2rem;
  font-size: 1.2rem;
  color: #000000;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 2;
}

.contact--form--primary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 0.5rem; */
  column-gap: 1rem;
  /* row-gap: 2rem; */
}

.contact--form--primary div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* margin: 1rem;
    padding: 1rem; */
}
.contact--form--primary div input {
  border-radius: 0.5rem;
  border: 1px solid black;
  height: 4rem;
}
.contact--form--primary div input::placeholder {
  padding: 0 1rem;
}

.contact--form--second {
  margin-top: 1rem;
  height: 100%;
}

.contact--form--second div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
/* 
  .message {
    height: 100%;
  } */

.contact--form--second textarea {
  border-radius: 0.5rem;
  border: 1px solid black;
}

.contact--form--second button {
  height: 5rem;
  background-color: #000;
  border-radius: 1rem;
  color: white;
  cursor: pointer;
  /* margin-bottom: 5rem; */
}

.contact--form--second button:hover {
  background-color: rgba(0, 0, 0, 0.459);
  transition: all 1s;
}

.paper {
  color: #000000;
  font-size: 1.2rem;
  position: relative;
  top: 1rem;
  height: 90vh;
  width: 40vw;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);

  -webkit-animation-name: levitate;
  animation-name: levitate;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.paper::before {
  content: "";
  position: absolute;
  left: 45px;
  height: 100%;
  width: 2px;
  background: rgba(255, 0, 0, 0.4);
}
.lines {
  margin-top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  background-image: repeating-linear-gradient(
    white 0px,
    white 24px,
    steelblue 25px
  );
}
.Lettertext {
  position: absolute;
  top: 65px;
  left: 55px;
  bottom: 10px;
  right: 10px;
  line-height: 25px;
  font-family: "Bad Script", cursive;
  font-weight: bold;
  overflow: hidden;
  outline: none;
}

@media only screen and (max-width: 649px) {
  .contact--container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  /* 
  .contact--container > div:nth-of-type(1) {
    order: 2;
  }
  .contact--container > div:nth-of-type(2) {
    order: 1;
  } */
  .paper {
    width: 90vw;
    height: 100vh;
  }
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact--form {
    margin-top: 10vh;
    width: 100%;
    height: 100%;
    margin-bottom: 5vh;
  }

  .contact--form--primary {
    /* column-gap: 1rem; */
    row-gap: 2rem;
  }
}
